<!-- This example requires Tailwind CSS v2.0+ -->
<template>
   <div>
      <TransitionRoot
         as="template"
         :show="open_edit"
      >
         <Dialog
            as="div"
            static
            class="fixed inset-0 overflow-hidden"
            @close="open_edit=false"
            :open="open_edit"
         >
            <div class="absolute inset-0 overflow-hidden">
               <DialogOverlay class="absolute inset-0" />
               <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                  <TransitionChild
                     as="template"
                     enter="transform transition ease-in-out duration-500 sm:duration-700"
                     enter-from="translate-x-full"
                     enter-to="translate-x-0"
                     leave="transform transition ease-in-out duration-500 sm:duration-700"
                     leave-from="translate-x-0"
                     leave-to="translate-x-full"
                  >
                     <div class="w-screen max-w-md">
                        <div class=" h-full flex flex-col bg-white shadow-xl overflow-y-scroll ">
                           <div class="py-6 px-4 bg-light-blue-600 sm:px-6">
                              <div class="flex items-center justify-between">
                                 <DialogTitle class="text-lg font-medium text-white"> Edit Legal Fee </DialogTitle>
                                 <div class="ml-3 h-7 flex items-center">
                                    <button
                                       class=" bg-light-blue-900 rounded-md text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white "
                                       @click="open_edit=false"
                                    >
                                       <span class="sr-only">Close panel</span>
                                       <XIcon
                                          class="h-6 w-6"
                                          aria-hidden="true"
                                       />
                                       </button>
                                 </div>
                              </div>
                              <div class="mt-1">
                                 <p class="text-sm text-blue-300"> Fill in the information below to create a new legal fee. </p>
                              </div>
                           </div>
                           <div class="relative flex-1 py-6 px-4 sm:px-6">
                              <div class="space-y-6 sm:space-y-5">
                                 <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
                                    <label
                                       for="client_name"
                                       class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                    > Title </label>
                                       <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                             type="text"
                                             name="title"
                                             id="title"
                                             autocomplete="off"
                                             class=" max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md "
                                          /> </div>
                                 </div>
                                 <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                    <label
                                       for="range_from"
                                       class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                    > Price range from </label>
                                       <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                             @input="handleOnChange"
                                             type="text"
                                             name="range_from"
                                             id="range_from"
                                             autocomplete="off"
                                             class=" max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md "
                                          /> </div>
                                 </div>
                                 <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                    <label
                                       for="range_to"
                                       class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                    > Price range to </label>
                                       <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                             @input="handleOnChange"
                                             id="range_to"
                                             name="range_to"
                                             type="text"
                                             class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                          /> </div>
                                 </div>
                                 <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                    <label
                                       for="subject"
                                       class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                    > Basic Fee </label>
                                       <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                             @input="handleOnChange"
                                             id="fee"
                                             name="fee"
                                             type="text"
                                             class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                          /> </div>
                                 </div>
                                 <div v-if="type_of_sale != 'sale'" class="
                                          sm:grid
                                          sm:grid-cols-3
                                          sm:gap-4
                                          sm:items-start
                                          sm:border-t
                                          sm:border-gray-200
                                          sm:pt-5
                                       ">
                                          <label
                                          for="stamp_duty_fee"
                                          class="
                                          block
                                          text-sm
                                          font-medium
                                          text-gray-700
                                          sm:mt-px sm:pt-2
                                          "
                                          >
                                          Stamp duty
                                          </label>
                                          <div class="mt-1 sm:mt-0 sm:col-span-2">
                                             <select
                                                id="buyer"
                                                name="buyer"
                                                class="
                                                form-select appearance-none
                                                block
                                                w-full
                                                px-3
                                                py-1.5
                                                text-base
                                                font-normal
                                                text-gray-700
                                                bg-white bg-clip-padding bg-no-repeat
                                                border border-solid border-gray-300
                                                rounded
                                                transition
                                                ease-in-out
                                                m-0
                                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                                                "
                                             >
                                                <option value="" selected>Select Type</option>
                                                <option value="first_time">First Time Buyer</option>
                                                <option value="normal">Standard</option>
                                                <option value="additional">Higher rate</option>

                                                </select>
                                          </div>
                                       </div>
                                       <div class="
                                          sm:grid
                                          sm:grid-cols-3
                                          sm:gap-4
                                          sm:items-start
                                          sm:border-t
                                          sm:border-gray-200
                                          sm:pt-5
                                       ">
                                          <label
                                          for="admin"
                                          class="
                                          block
                                          text-sm
                                          font-medium
                                          text-gray-700
                                          sm:mt-px sm:pt-2
                                          "
                                          >
                                          Quote section
                                          </label>
                                          <div class="mt-1 sm:mt-0 sm:col-span-2">
                                             <select
                                                id="admin"
                                                name="admin"
                                                class="
                                                form-select appearance-none
                                                block
                                                w-full
                                                px-3
                                                py-1.5
                                                text-base
                                                font-normal
                                                text-gray-700
                                                bg-white bg-clip-padding bg-no-repeat
                                                border border-solid border-gray-300
                                                rounded
                                                transition
                                                ease-in-out
                                                m-0
                                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                                                "
                                             >
                                                <option value="" selected>Select Type</option>
                                                <option value="true"> Admin</option>
                                                <option value="false"> Customers </option>

                                                </select>
                                          </div>
                                       </div>
                                       <div class="
                                          sm:grid
                                          sm:grid-cols-3
                                          sm:gap-4
                                          sm:items-start
                                          sm:border-t
                                          sm:border-gray-200
                                          sm:pt-5
                                       ">
                                          <label
                                          for="type_of_sale"
                                          class="
                                          block
                                          text-sm
                                          font-medium
                                          text-gray-700
                                          sm:mt-px sm:pt-2
                                          "
                                          >
                                          Transaction
                                          </label>
                                          <div class="mt-1 sm:mt-0 sm:col-span-2">
                                             <select
                                                id="type_of_sale"
                                                name="type_of_sale"
                                                v-model="type_of_sale"
                                                class="
                                                form-select appearance-none
                                                block
                                                w-full
                                                px-3
                                                py-1.5
                                                text-base
                                                font-normal
                                                text-gray-700
                                                bg-white bg-clip-padding bg-no-repeat
                                                border border-solid border-gray-300
                                                rounded
                                                transition
                                                ease-in-out
                                                m-0
                                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                                                "
                                             >
                                                <option value="" selected>Select Type</option>
                                                <option
                                                v-for="(type) in types"
                                                :key="type.uid"
                                                :value="type.uid.trim()"
                                                
                                                >{{type.name}}</option>
                                                </select>
                                          </div>
                                       </div>
                                       
                                       <div v-if="type_of_sale != 'sale'" class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Land Registry Fee </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="land_registry_fee"
                                                   name="land_registry_fee"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div v-if="type_of_sale != 'sale'" class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Land Registry Searches </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="land_registry_search"
                                                   name="land_registry_search"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                          <label
                                             for="storage_fee"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Storage Fee </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="storage_fee"
                                                   name="storage_fee"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div v-if="type_of_sale != 'sale'" class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Search Packs</label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="search_packs"
                                                   name="search_packs"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > VAT </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="vat"
                                                   name="vat"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div v-if="type_of_sale != 'sale'" class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Mortgage Fee </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="mortgage_fee"
                                                   name="mortgage_fee"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div v-if="type_of_sale != 'purchase'" class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Mortgage Discharge Fee </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="mortgage_discharge_fee"
                                                   name="mortgage_discharge_fee"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div v-if="type_of_sale != 'purchase'" class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Draft Contract Fee </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="draft_contract_fee"
                                                   name="draft_contract_fee"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 " style="display:none;">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > ID verification Fee </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="id_verfication_fee"
                                                   name="id_verfication_fee"
                                                   style="display:none;"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Electronic Transfer Fee </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="electronic_transfer_fee"
                                                   name="electronic_transfer_fee"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 " style="display:none;">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Confirmation of Transfer Fee </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="transfer_fee"
                                                   name="transfer_fee"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 " style="display:none;">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Archive Fee </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="archive_fee"
                                                   name="archive_fee"
                                                   style="display:none;"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 " style="display:none;">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Verification of Lawyer Fee </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="lawyer_fee"
                                                   name="lawyer_fee"
                                                   style="display:none;"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 " style="display:none;">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Confirmation of Ownership Fee </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="ownership_fee"
                                                   name="ownership_fee"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div v-if="type_of_sale != 'sale'" class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Bankruptcy Fee </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="bankruptcy_fee"
                                                   name="bankruptcy_fee"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>

                              </div>
                              <div class=" sm:grid sm:grid-cols-1 text-red-700 help-desk-error  px-4 py-4"></div>
                              <div class=" sm:grid sm:grid-cols-1 text-green-700 help-desk-success  px-4 py-4"></div>
                           </div>
                           <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                              <button
                                 type="button"
                                 class=" bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 "
                                 @click="open_edit=false"
                              > Cancel </button>
                                 <button
                                    type="submit"
                                    class=" ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 "
                                    @click="updateLegalData"
                                 > Save </button>
                           </div>
                        </div>
                     </div>
                     </TransitionChild>
               </div>
            </div>
            </Dialog>
            </TransitionRoot>
            <TransitionRoot
               as="template"
               :show="open"
            >
               <Dialog
                  as="div"
                  static
                  class="fixed inset-0 overflow-hidden"
                  @close="open=false"
                  :open="open"
               >
                  <div class="absolute inset-0 overflow-hidden">
                     <DialogOverlay class="absolute inset-0" />
                     <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                        <TransitionChild
                           as="template"
                           enter="transform transition ease-in-out duration-500 sm:duration-700"
                           enter-from="translate-x-full"
                           enter-to="translate-x-0"
                           leave="transform transition ease-in-out duration-500 sm:duration-700"
                           leave-from="translate-x-0"
                           leave-to="translate-x-full"
                        >
                           <div class="w-screen max-w-md">
                              <div class=" h-full flex flex-col bg-white shadow-xl overflow-y-scroll ">
                                 <div class="py-6 px-4 bg-light-blue-600 sm:px-6">
                                    <div class="flex items-center justify-between">
                                       <DialogTitle class="text-lg font-medium text-white"> Add Legal Fee </DialogTitle>
                                       <div class="ml-3 h-7 flex items-center">
                                          <button
                                             class=" bg-light-blue-900 rounded-md text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white "
                                             @click="open=false"
                                          >
                                             <span class="sr-only">Close panel</span>
                                             <XIcon
                                                class="h-6 w-6"
                                                aria-hidden="true"
                                             />
                                             </button>
                                       </div>
                                    </div>
                                    <div class="mt-1">
                                       <p class="text-sm text-blue-300"> Fill in the information below to create a new legal fee. </p>
                                    </div>
                                 </div>
                                 <div class="relative flex-1 py-6 px-4 sm:px-6">
                                    <div class="space-y-6 sm:space-y-5">
                                       <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
                                          <label
                                             for="client_name"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Title </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   type="text"
                                                   name="title"
                                                   id="title"
                                                   autocomplete="off"
                                                   class=" max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                          <label
                                             for="range_from"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Price range from </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   @input="handleOnChange"
                                                   type="text"
                                                   name="range_from"
                                                   id="range_from"
                                                   autocomplete="off"
                                                   class=" max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Price range to </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   @input="handleOnChange"
                                                   id="range_to"
                                                   name="range_to"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                          <label
                                             for="subject"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Basic Fee </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   @input="handleOnChange"
                                                   id="fee"
                                                   name="fee"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div class="
                                          sm:grid
                                          sm:grid-cols-3
                                          sm:gap-4
                                          sm:items-start
                                          sm:border-t
                                          sm:border-gray-200
                                          sm:pt-5
                                       ">
                                          <label
                                          for="type_of_sale"
                                          class="
                                          block
                                          text-sm
                                          font-medium
                                          text-gray-700
                                          sm:mt-px sm:pt-2
                                          "
                                          >
                                          Stamp duty
                                          </label>
                                          <div class="mt-1 sm:mt-0 sm:col-span-2">
                                             <select
                                                id="buyer"
                                                name="buyer"
                                                class="
                                                form-select appearance-none
                                                block
                                                w-full
                                                px-3
                                                py-1.5
                                                text-base
                                                font-normal
                                                text-gray-700
                                                bg-white bg-clip-padding bg-no-repeat
                                                border border-solid border-gray-300
                                                rounded
                                                transition
                                                ease-in-out
                                                m-0
                                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                                                "
                                             >
                                                <option value="" selected>Select Type</option>
                                                <option value="first_time">First Time Buyer</option>
                                                <option value="normal">Standard</option>
                                                <option value="additional">Higher rate</option>

                                                </select>
                                          </div>
                                       </div>
                                       <div class="
                                          sm:grid
                                          sm:grid-cols-3
                                          sm:gap-4
                                          sm:items-start
                                          sm:border-t
                                          sm:border-gray-200
                                          sm:pt-5
                                       ">
                                          <label
                                          for="admin"
                                          class="
                                          block
                                          text-sm
                                          font-medium
                                          text-gray-700
                                          sm:mt-px sm:pt-2
                                          "
                                          >
                                          Quote section
                                          </label>
                                          <div class="mt-1 sm:mt-0 sm:col-span-2">
                                             <select
                                                id="admin"
                                                name="admin"
                                                class="
                                                form-select appearance-none
                                                block
                                                w-full
                                                px-3
                                                py-1.5
                                                text-base
                                                font-normal
                                                text-gray-700
                                                bg-white bg-clip-padding bg-no-repeat
                                                border border-solid border-gray-300
                                                rounded
                                                transition
                                                ease-in-out
                                                m-0
                                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                                                "
                                             >
                                                <option value="" selected>Select Type</option>
                                                <option value="true">Admin</option>
                                                <option value="false">customers </option>

                                                </select>
                                          </div>
                                       </div>
                                       <div class="
                                          sm:grid
                                          sm:grid-cols-3
                                          sm:gap-4
                                          sm:items-start
                                          sm:border-t
                                          sm:border-gray-200
                                          sm:pt-5
                                       ">
                                          <label
                                          for="type_of_sale"
                                          class="
                                          block
                                          text-sm
                                          font-medium
                                          text-gray-700
                                          sm:mt-px sm:pt-2
                                          "
                                          >
                                          Transaction
                                          </label>
                                          <div class="mt-1 sm:mt-0 sm:col-span-2">
                                             <select
                                                id="type_of_sale"
                                                name="type_of_sale"
                                                v-model="type_of_sale"
                                                class="
                                                form-select appearance-none
                                                block
                                                w-full
                                                px-3
                                                py-1.5
                                                text-base
                                                font-normal
                                                text-gray-700
                                                bg-white bg-clip-padding bg-no-repeat
                                                border border-solid border-gray-300
                                                rounded
                                                transition
                                                ease-in-out
                                                m-0
                                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                                                "
                                             >
                                                <option value="" selected>Select Type</option>
                                                <option
                                                v-for="(type) in types"
                                                :key="type.uid"
                                                :value="type.uid.trim()"
                                                >{{type.name}}</option>
                                                </select>
                                          </div>
                                       </div>
                                       
                                       <div v-if="type_of_sale != 'sale'" class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Land Registry Fee </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="land_registry_fee"
                                                   name="land_registry_fee"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div v-if="type_of_sale != 'sale'" class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Land Registry Searches </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="land_registry_search"
                                                   name="land_registry_search"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                          <label
                                             for="storage_fee"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Storage Fee </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="storage_fee"
                                                   name="storage_fee"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div  v-if="type_of_sale != 'sale'" class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Search Packs</label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="search_packs"
                                                   name="search_packs"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > VAT </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="vat"
                                                   name="vat"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div v-if="type_of_sale != 'sale'" class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Mortgage Fee </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="mortgage_fee"
                                                   name="mortgage_fee"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div v-if="type_of_sale != 'purchase'" class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Mortgage Discharge Fee </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="mortgage_discharge_fee"
                                                   name="mortgage_discharge_fee"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div v-if="type_of_sale != 'purchase'" class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Draft Contract Fee </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="draft_contract_fee"
                                                   name="draft_contract_fee"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 " style="display:none;">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > ID verification Fee </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="id_verfication_fee"
                                                   name="id_verfication_fee"
                                                   style="display:none;"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Electronic Transfer Fee </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="electronic_transfer_fee"
                                                   name="electronic_transfer_fee"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 " style="display:none;">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Confirmation of Transfer Fee </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="transfer_fee"
                                                   name="transfer_fee"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 " style="display:none;">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Archive Fee </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="archive_fee"
                                                   name="archive_fee"
                                                   style="display:none;"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 " style="display:none;">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Verification of Lawyer Fee </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="lawyer_fee"
                                                   name="lawyer_fee"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 " style="display:none;">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Confirmation of Ownership Fee </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="ownership_fee"
                                                   name="ownership_fee"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>
                                       <div v-if="type_of_sale != 'sale'" class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                          <label
                                             for="range_to"
                                             class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                          > Bankruptcy Fee </label>
                                             <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                   id="bankruptcy_fee"
                                                   name="bankruptcy_fee"
                                                   type="text"
                                                   class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                                   required
                                                /> </div>
                                       </div>

                                       <div class=" sm:grid sm:grid-cols-1 text-red-700 help-desk-error  px-4 py-4"></div>
                                       <div class=" sm:grid sm:grid-cols-1 text-green-700 help-desk-success  px-4 py-4"></div>
                                    </div>
                                 </div>
                                 <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                                    <button
                                       type="button"
                                       class=" bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 "
                                       @click="open=false"
                                    > Cancel </button>
                                       <button
                                          type="submit"
                                          class=" ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 "
                                          @click="insertLegalData"
                                       > Save </button>
                                 </div>
                              </div>
                           </div>
                           </TransitionChild>
                     </div>
                  </div>
                  </Dialog>
                  </TransitionRoot>
                  <div class="flex flex-col">
                     <div class="-my-2 sm:-mx-6 lg:-mx-8">
                        <nav
                           class=" flex mb-8 justify-between sm:px-6 lg:px-8 flex-wrap gap-y-4 lg:h-10 md:h-10 "
                           aria-label="Breadcrumb"
                        >
                           <ol class="flex items-center space-x-4">
                              <li>
                                 <div>
                                    <a
                                       href="#"
                                       class="text-gray-400 hover:text-gray-500"
                                    >
                                       <HomeIcon
                                          class="flex-shrink-0 h-5 w-5"
                                          aria-hidden="true"
                                       />
                                       <span class="sr-only">Home</span>
                                       </a>
                                 </div>
                              </li>
                              <li
                                 v-for="page in pages"
                                 :key="page.name"
                              >
                                 <div class="flex items-center">
                                    <ChevronRightIcon
                                       class="flex-shrink-0 h-5 w-5 text-gray-400"
                                       aria-hidden="true"
                                    />
                                    <a
                                       :href="page.href"
                                       class=" ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 "
                                       :aria-current="page.current ? 'page' : undefined"
                                    >{{page.name}}</a>
                                 </div>
                                 </li>
                           </ol>
                           <div class="flex flex-wrap gap-4 items-center">
                              <div class="relative rounded-md shadow-sm">
                                 <div class=" absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none ">
                                    <SearchIcon
                                       class="h-5 w-5 text-gray-400"
                                       aria-hidden="true"
                                    />
                                 </div>
                                 <input
                                    type="text"
                                    name="email"
                                    class=" focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md "
                                    placeholder="Search for fees"
                                 />
                              </div>
                              <button
                                 @click="open=true"
                                 type="button"
                                 class=" inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-light-blue-600 hover:bg-light-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500 "
                              >
                                 <FolderAddIcon
                                    class="mr-3 h-5 w-5"
                                    aria-hidden="true"
                                 /> Add Legal Fee
                                 </button>
                           </div>
                           </nav>
                           <div
                              class=" py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 max-w-full "
                              v-if="cases"
                           >
                              <div v-if="cases.items.length > 0"
                                 class=" shadow border-b border-gray-200 sm:rounded-tl-lg sm:rounded-tr-lg overflow-auto "
                              >
                                 <table class="min-w-full divide-y divide-gray-200">
                                    <thead class="bg-gray-50">
                                       <tr>
                                          <th
                                             scope="col"
                                             class=" px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider "
                                          > NAME & START DATE </th>
                                             <th
                                                scope="col"
                                                class=" px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider "
                                             > PRICE FROM </th>
                                                <th
                                                   scope="col"
                                                   class=" px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider "
                                                > PRICE TO </th>
                                                   <th
                                                      scope="col"
                                                      class=" px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider "
                                                   > FEES </th>
                                                   <th
                                                      scope="col"
                                                      class=" px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider "
                                                      > Transaction </th>
                                                      <th
                                                      scope="col"
                                                      class=" px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider "
                                                      > Land Registry Fee </th>
                                                      <th
                                                      scope="col"
                                                      class=" px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider "
                                                      > VAT </th>
                                                      <th
                                                         scope="col"
                                                         class="relative px-6 py-3"
                                                      >
                                                         <span class="sr-only">Edit</span>
                                                         </th>
                                       </tr>
                                    </thead>
                                    <tbody class="bg-white divide-y divide-gray-200">
                                       <tr
                                          :class="i % 2===0 ? 'bg-white' : 'bg-gray-50'"
                                          v-for="(item, i) in cases.items"
                                          :key="item.id"
                                       >
                                          <td class="px-6 py-4 whitespace-nowrap">
                                             <div class="flex items-center">
                                                <div>
                                                   <div
                                                      :title="item.name"
                                                      class=" text-sm font-medium text-gray-900 lg:truncate md:truncate max-w-xs "
                                                   >{{item.name}}</div>
                                                <div class="text-sm text-gray-500">{{DateTime.fromISO(item.created_at).toFormat( "MMMM dd, yyyy" )}}</div>
                                             </div>
                              </div>
                              </td>
                              <td class="px-6 py-4 whitespace-nowrap">
                                 <div class="flex items-center">
                                    <div>
                                       <div
                                          :title="item.price_from"
                                          class=" text-sm font-medium text-gray-900 lg:truncate md:truncate max-w-xs "
                                       >{{item.price_from}}</div>
                                 </div>
                     </div>
                     </td>
                     <td class="px-6 py-4 whitespace-nowrap">
                        <div class="flex items-center">
                           <div>
                              <div
                                 :title="item.price_to"
                                 class=" text-sm font-medium text-gray-900 lg:truncate md:truncate max-w-xs "
                              >{{item.price_to}}</div>
                        </div>
                  </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                     <div class="flex items-center">
                        <div>
                           <div
                              :title="item.fees"
                              class=" text-sm font-medium text-gray-900 lg:truncate md:truncate max-w-xs "
                           >{{item.fees}}</div>
                        </div>
                     </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                     <div class="flex items-center">
                        <div v-for="(type) of types" :key="type.uid" :value="type.uid">
                           <div
                              :title="item.type_of_sale"
                              v-if="type.uid == item.type_of_sale"
                              class=" text-sm font-medium text-gray-900 lg:truncate md:truncate max-w-xs "
                           >{{type.name}}</div>
                        </div>
                     </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                     <div class="flex items-center">
                        <div>
                           <div
                              :title="item.land_registry_fee"
                              class=" text-sm font-medium text-gray-900 lg:truncate md:truncate max-w-xs "
                           >{{item.land_registry_fee}}</div>
                        </div>
                     </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                     <div class="flex items-center">
                        <div>
                           <div
                              :title="item.vat_fee"
                              class=" text-sm font-medium text-gray-900 lg:truncate md:truncate max-w-xs "
                           >{{item.vat_fee}}</div>
                        </div>
                     </div>
                  </td>
   <td class="px-6 py-4 whitespace-nowrap">
      <div>
         <span
            @click="openModal(item.id)"
            class="text-blue-500 cursor-pointer px-2"
         >Edit</span>
            <span
               @click="deleteLegalFee(item.id)"
               class="text-gray-500 cursor-pointer px-2"
            >Delete</span>
      </div>
   </td>
   </tr>
   </tbody>
   </table>
   </div>
   <Pagination
      @page-changed="handlePageChanged"
      :total="cases.count"
      :current_page="current_page"
   />
   </div>
   </div>
   </div>
   </div>
</template>
<script>
import {
  ChevronRightIcon,
  HomeIcon,
  SearchIcon,
  FolderAddIcon
} from "@heroicons/vue/solid";
import { useStore } from "vuex";
import { DateTime } from "luxon";
// import axios from 'axios';
const pages = [{ name: "Legal-fee", href: "#", current: true }];

import { ref, computed , onMounted} from "vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";
import Pagination from "@/components/Pagination";
import { setTimeout } from "timers";
import { getAppTypes } from "../../../services/case";
// import Avatar from "@/components/Avatar";

export default {
  components: {
    //  Avatar,
    ChevronRightIcon,
    HomeIcon,
    SearchIcon,
    FolderAddIcon,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    Pagination
  },
  setup() {
    const store = useStore();

    store.dispatch("cases/getLegelFees");

    const open = ref(false);
    const open_edit = ref(false);
    const current_page = ref(1);
    const updated_id = ref(0);
    const types = ref("");

    const handlePageChanged = async page => {
      await store.dispatch("cases/getLegelFees", page);
      current_page.value = page;
    };
    
    onMounted(async () => {
      try {
        const { data } = await getAppTypes();
        types.value = data;
      } catch (e) {
        console.log(e);
      }
    });
    
    //create legal-fee
    const insertLegalData = async () => {
      // console.log("yes")
      document.getElementsByClassName("help-desk-error")[0].innerHTML = "";
      document.getElementsByClassName("help-desk-success")[0].innerHTML = "";
      let title = document.getElementById("title").value;
      let range_from = document
        .getElementById("range_from")
        .value.replaceAll(",", "");
      let range_to = document
        .getElementById("range_to")
        .value.replaceAll(",", "");
      let fee = document.getElementById("fee").value.replaceAll(",", "");
      if (parseFloat(range_from) > parseFloat(range_to)) {
        document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Price Range To should be greater than Price range From ";
        return;
      }
      
      let buyer = document.getElementById("buyer").value;
      let type_of_sale = document.getElementById("type_of_sale").value;
      let land_registry_fee = document.getElementById("land_registry_fee").value;
      let land_registry_search = document.getElementById("land_registry_search").value;
      let search_packs = document.getElementById("search_packs").value;
      let vat_fee = document.getElementById("vat").value;
      let mortgage_fee = document.getElementById("mortgage_fee").value;
      let id_verfication_fee = document.getElementById("id_verfication_fee").value;
      let electronic_transfer_fee = document.getElementById("electronic_transfer_fee").value;
      let archive_fee = document.getElementById("archive_fee").value;
      let lawyer_fee = document.getElementById("lawyer_fee").value;
      let storage_fee = document.getElementById("storage_fee").value;
      let mortgage_discharge_fee = document.getElementById("mortgage_discharge_fee").value;
      let draft_contract_fee = document.getElementById("draft_contract_fee").value;
      let ownership_fee = document.getElementById("ownership_fee").value;
      let bankruptcy_fee = document.getElementById("bankruptcy_fee").value;
      let transfer_fee = document.getElementById("transfer_fee").value;
      let admin = document.getElementById("admin").value; 
      // console.log('admin' ,admin);

      if(isNaN(land_registry_fee) && land_registry_fee != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Land Registry Fee is not numeric";
         return;
      }
      
      if(land_registry_fee == ""){
         land_registry_fee = 0;
      }
      
      if(isNaN(land_registry_search) && land_registry_search != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Land Registry Searches is not numeric";
         return;
      }
      
      if(land_registry_search == ""){
         land_registry_search = 0;
      }

      if(isNaN(search_packs) && search_packs != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Search Packs is not numeric";
         return;
      }

      if(search_packs == ""){
         search_packs = 0;
      }

      if(isNaN(vat_fee) && vat_fee != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "VAT Fee  is not numeric";
         return;
      }
      
      if(vat_fee == ""){
         vat_fee = 0;
      }

      if(isNaN(mortgage_fee) && mortgage_fee != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Mortgage Fee is not numeric";
         return;
      }

      if(mortgage_fee == ""){
         mortgage_fee = 0;
      }

      if(isNaN(id_verfication_fee) && id_verfication_fee != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Id Verfication Fee is not numeric";
         return;
      }

      if(id_verfication_fee == ""){
         id_verfication_fee = 0;
      }

      if(isNaN(electronic_transfer_fee) && electronic_transfer_fee != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Electronic Transfer Fee is not numeric";
         return;
      }

      if(electronic_transfer_fee == ""){
         electronic_transfer_fee = 0;
      }

      if(isNaN(archive_fee) && archive_fee != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Archive Fee is not numeric";
         return;
      }
      
      if(archive_fee == ""){
         archive_fee = 0;
      }

      if(isNaN(lawyer_fee) && lawyer_fee != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Lawyer Fee is not numeric";
         return;
      }
      
      if(lawyer_fee == ""){
         lawyer_fee = 0;
      }

      if(isNaN(storage_fee) && storage_fee != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Storage Fee is not numeric";
         return;
      }
      
      if(storage_fee == ""){
         storage_fee = 0;
      }

      if(isNaN(mortgage_discharge_fee) && mortgage_discharge_fee != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Mortgage Discharge Fee is not numeric";
         return;
      }
      
      if(mortgage_discharge_fee == ""){
         mortgage_discharge_fee = 0;
      }

      if(isNaN(draft_contract_fee) && draft_contract_fee != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Draft Contract Fee is not numeric";
         return;
      }
      
      if(draft_contract_fee == ""){
         draft_contract_fee = 0;
      }

      if(isNaN(ownership_fee) && ownership_fee != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Ownership Fee is not numeric";
         return;
      }
      
      if(ownership_fee == ""){
         ownership_fee = 0;
      }

      if(isNaN(bankruptcy_fee) && bankruptcy_fee != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Bankruptcy Fee is not numeric";
         return;
      }
      
      if(bankruptcy_fee == ""){
         bankruptcy_fee = 0;
      }

      if(isNaN(transfer_fee) && transfer_fee != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Bankruptcy Fee is not numeric";
         return;
      }
      
      if(transfer_fee == ""){
         transfer_fee = 0;
      }

      if (title != "" && range_from != "" && range_to != "" && fee != "") {
        // console.log("inside")
        let page = {
          name: title,
          price_from: range_from,
          price_to: range_to,
          fees: fee,
          admin: admin,  //check for admin use or not
          buyer: buyer, //stamp duty type
          type_of_sale: type_of_sale, //type_of_sale type
          land_registry_fee: land_registry_fee, //land Registry fee
          land_registry_search: land_registry_search, //land Registry fee
          search_packs: search_packs, //land Registry fee
          vat_fee: vat_fee, //vat_fee
          mortgage_fee: mortgage_fee, //Mortgage_Fee
          id_verfication_fee: id_verfication_fee, //ID verification Fee
          electronic_transfer_fee: electronic_transfer_fee, //Electronic Transfer fee
          archive_fee: archive_fee, //Archive Fee
          lawyer_fee: lawyer_fee, //Verification of Lawyer Fee
          storage_fee: storage_fee, //Storage Fee
          mortgage_discharge_fee: mortgage_discharge_fee, //Mortgage Fee
          draft_contract_fee: draft_contract_fee, //Draft Contract Fee
          ownership_fee: ownership_fee, //Ownership Fee
          bankruptcy_fee: bankruptcy_fee, //Bankruptcy Fee
          transfer_fee: transfer_fee //Confirmation Transfer Fee
        };
        // console.log(page)
        // console.log("data >> already ", store.state.cases.legalFee)
      //   let allLegalData = store.state.cases.legalFee.items;
      //   let filterData = allLegalData.find(itm => {
      //     console.log(
      //       "itm",
      //       itm.price_from,
      //       itm.price_to,
      //       "--",
      //       range_from,
      //       range_to
      //     );
      //     return (
      //       (itm.price_from <= range_from && itm.price_to >= range_from) ||
      //       (itm.price_from <= range_to && itm.price_to >= range_to)
      //     );
      //   });
      //   console.log("filterData", filterData)
      //   if (filterData == undefined) {
          await store.dispatch("cases/createLegalFee", page);
          document.getElementById("title").value = "";
          document.getElementById("range_from").value = "";
          document.getElementById("range_to").value = "";
          document.getElementById("fee").value = "";
          document.getElementsByClassName("help-desk-success")[0].innerHTML =
            "Data added successfully!";
          setTimeout(function() {
            open.value = false;
          }, 800);
      //   } else {
      //     document.getElementsByClassName("help-desk-error")[0].innerHTML =
      //       "Range already exists";
      //   }
      } else {
        document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Please fill all input fields";
      }
    };

    //edit fetch data legal-fee
    const openModal = async legalFeeId => {
      if (open_edit.value == true) {
        open_edit.value = false;
      } else {
        open_edit.value = true;
        await store.dispatch("cases/getLegalFee", legalFeeId);
        // console.log("store.state.cases.legalData", store.state.cases.legalData)
        const respData = store.state.cases.legalData;
        document.getElementById("title").value = respData.name;
        document.getElementById("range_from").value = respData.price_from;
        document.getElementById("range_to").value = respData.price_to;
        document.getElementById("fee").value = respData.fees;
        if(respData.buyer){
            document.getElementById("buyer").value = respData.buyer;
        }
        if(respData.type_of_sale){
            document.getElementById("type_of_sale").value = respData.type_of_sale;
        }
       
        document.getElementById("land_registry_fee").value  = respData.land_registry_fee ? respData.land_registry_fee : '';
        document.getElementById("land_registry_search").value = respData.land_registry_search ?  respData.land_registry_search : '' ;
        document.getElementById("search_packs").value = respData.search_packs ? respData.search_packs : '';
        document.getElementById("vat").value = respData.vat_fee ? respData.vat_fee : '' ;
        document.getElementById("mortgage_fee").value = respData.mortgage_fee ? respData.mortgage_fee : '';
        document.getElementById("id_verfication_fee").value = respData.id_verfication_fee ? respData.id_verfication_fee : '';
        document.getElementById("electronic_transfer_fee").value = respData.electronic_transfer_fee ? respData.electronic_transfer_fee : '';
        document.getElementById("archive_fee").value = respData.archive_fee ? respData.archive_fee : '';
        document.getElementById("lawyer_fee").value = respData.lawyer_fee ? respData.lawyer_fee : '';
        document.getElementById("storage_fee").value = respData.storage_fee ? respData.storage_fee : '';
        document.getElementById("mortgage_discharge_fee").value = respData.mortgage_discharge_fee ? respData.mortgage_discharge_fee : '';
        document.getElementById("draft_contract_fee").value = respData.draft_contract_fee ? respData.draft_contract_fee : '';
        document.getElementById("ownership_fee").value = respData.ownership_fee ? respData.ownership_fee : '';
        document.getElementById("bankruptcy_fee").value  = respData.bankruptcy_fee ? respData.bankruptcy_fee : '';
        document.getElementById("transfer_fee").value= respData.transfer_fee ? respData.transfer_fee : '';
      //   console.log('respData.admin',respData.admin);
        document.getElementById("admin").value = (respData.admin == true || respData.admin == false ) ? respData.admin : ''; 
        updated_id.value = legalFeeId;
      }
    };

    //update legal-fee
    const updateLegalData = async () => {
      document.getElementsByClassName("help-desk-error")[0].innerHTML = "";
      document.getElementsByClassName("help-desk-success")[0].innerHTML = "";

      let title = document.getElementById("title").value;
      let range_from = document
        .getElementById("range_from")
        .value.replaceAll(",", "");
      let range_to = document
        .getElementById("range_to")
        .value.replaceAll(",", "");
      let fee = document.getElementById("fee").value.replaceAll(",", "");

      let buyer = document.getElementById("buyer").value;
      let type_of_sale = document.getElementById("type_of_sale").value;
      let land_registry_fee = document.getElementById("land_registry_fee").value;
      let land_registry_search = document.getElementById("land_registry_search").value;
      let search_packs = document.getElementById("search_packs").value;
      let vat_fee = document.getElementById("vat").value;
      let mortgage_fee = document.getElementById("mortgage_fee").value;
      let id_verfication_fee = document.getElementById("id_verfication_fee").value;
      let electronic_transfer_fee = document.getElementById("electronic_transfer_fee").value;
      let archive_fee = document.getElementById("archive_fee").value;
      let lawyer_fee = document.getElementById("lawyer_fee").value;
      let storage_fee = document.getElementById("storage_fee").value;
      let mortgage_discharge_fee = document.getElementById("mortgage_discharge_fee").value;
      let draft_contract_fee = document.getElementById("draft_contract_fee").value;
      let ownership_fee = document.getElementById("ownership_fee").value;
      let bankruptcy_fee = document.getElementById("bankruptcy_fee").value;
      let transfer_fee = document.getElementById("transfer_fee").value;
      let admin = document.getElementById("admin").value; 
      // console.log('admin' ,admin);
      if(isNaN(land_registry_fee) && land_registry_fee != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Land Registry Fee is not numeric";
         return;
      }

      if(land_registry_fee == ""){
         land_registry_fee = 0;
      }
      
      if(isNaN(land_registry_search) && land_registry_search != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Land Registry Searches is not numeric";
         return;
      }
      
      if(land_registry_search == ""){
         land_registry_search = 0;
      }

      if(isNaN(search_packs) && search_packs != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Search Packs is not numeric";
         return;
      }

      if(search_packs == ""){
         search_packs = 0;
      }

      if(isNaN(vat_fee) && vat_fee != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "VAT Fee  is not numeric";
         return;
      }

      if(vat_fee == ""){
         vat_fee = 0;
      }

      if(isNaN(mortgage_fee) && mortgage_fee != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Mortgage Fee is not numeric";
         return;
      }
      if(mortgage_fee == ""){
         mortgage_fee = 0;
      }

      if(isNaN(id_verfication_fee) && id_verfication_fee != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Id Verfication Fee is not numeric";
         return;
      }
      
      if(id_verfication_fee == ""){
         id_verfication_fee = 0;
      }

      if(isNaN(electronic_transfer_fee) && electronic_transfer_fee != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Electronic Transfer Fee is not numeric";
         return;
      }
      
      if(electronic_transfer_fee == ""){
         electronic_transfer_fee = 0;
      }

      if(isNaN(archive_fee) && archive_fee != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Archive Fee is not numeric";
         return;
      }
      
      if(archive_fee == ""){
         archive_fee = 0;
      }

      if(isNaN(lawyer_fee) && lawyer_fee != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Lawyer Fee is not numeric";
         return;
      }
      
      if(lawyer_fee == ""){
         lawyer_fee = 0;
      }

      if(isNaN(storage_fee) && storage_fee != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Storage Fee is not numeric";
         return;
      }
      
      if(storage_fee == ""){
         storage_fee = 0;
      }

      if(isNaN(mortgage_discharge_fee) && mortgage_discharge_fee != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Mortgage Discharge Fee is not numeric";
         return;
      }
      
      if(mortgage_discharge_fee == ""){
         mortgage_discharge_fee = 0;
      }

      if(isNaN(draft_contract_fee) && draft_contract_fee != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Draft Contract Fee is not numeric";
         return;
      }
      
      if(draft_contract_fee == ""){
         draft_contract_fee = 0;
      }

      if(isNaN(ownership_fee) && ownership_fee != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Ownership Fee is not numeric";
         return;
      }
      
      if(ownership_fee == ""){
         ownership_fee = 0;
      }

      if(isNaN(bankruptcy_fee) && bankruptcy_fee != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Bankruptcy Fee is not numeric";
         return;
      }
      
      if(bankruptcy_fee == ""){
         bankruptcy_fee = 0;
      }

      if(isNaN(transfer_fee) && transfer_fee != ""){
         document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Bankruptcy Fee is not numeric";
         return;
      }
      
      if(transfer_fee == ""){
         transfer_fee = 0;
      }

      if (title != "" && range_from != "" && range_to != "" && fee != "") {
        let page = {
          name: title,
          price_from: range_from,
          price_to: range_to,
          fees: fee,
          admin: admin, //check for admin use or not
          buyer: buyer, //stamp duty type
          type_of_sale: type_of_sale, //type_of_sale type
          land_registry_fee: land_registry_fee, //land Registry fee
          land_registry_search: land_registry_search, //land Registry fee
          search_packs: search_packs, //land Registry fee
          vat_fee: vat_fee, //vat_fee
          mortgage_fee: mortgage_fee, //Mortgage_Fee
          id_verfication_fee: id_verfication_fee, //ID verification Fee
          electronic_transfer_fee: electronic_transfer_fee, //Electronic Transfer fee
          archive_fee: archive_fee, //Archive Fee
          lawyer_fee: lawyer_fee, //Verification of Lawyer Fee
          storage_fee: storage_fee, //Storage Fee
          mortgage_discharge_fee: mortgage_discharge_fee, //Mortgage Fee
          draft_contract_fee: draft_contract_fee, //Draft Contract Fee
          ownership_fee: ownership_fee, //Ownership Fee
          bankruptcy_fee: bankruptcy_fee, //Bankruptcy Fee
          transfer_fee: transfer_fee, //Confirmation Transfer Fee
          id: updated_id.value
        };
        console.log(page, updated_id.value);

      //   let allLegalData = store.state.cases.legalFee.items;
      //   let filterData = allLegalData.find(itm => {
      //     console.log(
      //       "itm",
      //       itm.price_from,
      //       itm.price_to,
      //       "--",
      //       range_from,
      //       range_to
      //     );
      //     if (itm.id != updated_id.value)
      //       return (
      //         (itm.price_from <= range_from && itm.price_to >= range_from) ||
      //         (itm.price_from <= range_to && itm.price_to >= range_to)
      //       );
      //   });
        // console.log("filterData", filterData)
      //   if (filterData == undefined) {
          await store.dispatch("cases/updateLegalFee", page);
          document.getElementsByClassName("help-desk-success")[0].innerHTML =
            "Data updated successfully!";
          setTimeout(function() {
            open_edit.value = false;
          }, 800);
      //   } else {
      //     document.getElementsByClassName("help-desk-error")[0].innerHTML =
      //       "Range already exists";
      //   }
      } else {
        document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Please fill all input fields";
      }
    };

    //delete legal fee
    const deleteLegalFee = async legalFeeId => {
      if (confirm("Are you sure?")) {
        await store.dispatch("cases/deleteLegalFee", legalFeeId);
      }
    };
    const handleOnChange = e => {
      let value = e.target.value.replaceAll(",", "");
      e.target.value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    return {
      handleOnChange,
      open,
      open_edit,
      pages,
      DateTime,
      handlePageChanged,
      current_page,
      cases: computed(() => store.state.cases.legalFee),
      insertLegalData,
      openModal,
      updated_id,
      updateLegalData,
      deleteLegalFee,
      types
      // cases: computed(() => store.state.cases.legalFee)
    };
  },
  data(){
     return {
        type_of_sale: '', 
     }
   },
};
</script>